<template>
  <div class="custom-report-page">
    <h1 class="text-3xl font-bold mb-6">Custom Report</h1>
    <Card>
      <template #content>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="filter-section">
            <h2 class="text-xl font-semibold mb-4">Filters</h2>
            <div class="flex flex-col space-y-4">
              <div>
                <label for="date-range" class="block mb-1">Date Range</label>
                <Calendar
                  v-model="dateRange"
                  selectionMode="range"
                  :showIcon="true"
                  inputId="date-range"
                  class="w-full"
                />
              </div>
              <div>
                <label for="partners-list" class="block mb-1"
                  >Select Partners</label
                >
                <MultiSelect
                  inputId="partners-list"
                  v-model="selectedPartners"
                  :options="memberList.partners"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Partners"
                  class="w-full"
                  display="chip"
                />
              </div>
              <div>
                <label for="groups-list" class="block mb-1"
                  >Select Groups</label
                >
                <MultiSelect
                  inputId="groups-list"
                  v-model="selectedGroups"
                  :options="memberList.groups"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Groups"
                  class="w-full"
                  display="chip"
                  filter
                />
              </div>
              <div>
                <label for="sellers-list" class="block mb-1"
                  >Select Sellers</label
                >
                <MultiSelect
                  inputId="sellers-list"
                  v-model="selectedSellers"
                  :options="memberList.sellers"
                  :optionLabel="(option) => `[${option.id}] ${option.name}`"
                  optionValue="id"
                  placeholder="Select Sellers"
                  class="w-full"
                  display="chip"
                  filter
                />
              </div>
            </div>
          </div>

          <div class="options-section">
            <div class="mb-6">
              <h2 class="text-xl font-semibold mb-4">Dimensions</h2>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="dimension in dimensions"
                  :key="dimension.name"
                  class="flex items-center"
                >
                  <Checkbox
                    :inputId="dimension.name"
                    v-model="selectedDimensions"
                    :value="dimension"
                    @change="validateSelections"
                  />
                  <label :for="dimension.name" class="ml-2">{{
                    dimension.name
                  }}</label>
                </div>
              </div>
            </div>

            <div>
              <h2 class="text-xl font-semibold mb-4">Metrics</h2>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="metric in availableMetrics"
                  :key="metric.name"
                  class="flex items-center"
                >
                  <Checkbox
                    :inputId="metric.name"
                    v-model="selectedMetrics"
                    :value="metric"
                    @change="validateSelections"
                  />
                  <label :for="metric.name" class="ml-2">{{
                    metric.name
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="validationWarnings.length > 0"
          class="validation-warnings mt-6"
        >
          <ul>
            <li v-for="warning in validationWarnings" :key="warning">
              {{ warning }}
            </li>
          </ul>
        </div>

        <div class="mt-6">
          <Button
            @click="runReport"
            :disabled="isLoading || !isValid"
            :label="runReportButtonLabel"
            class="p-button-primary"
          />
        </div>

        <ProgressSpinner v-if="isLoading" class="mt-6" />

        <div v-if="reportData" class="report-results mt-6">
          <DataTable
            :value="reportData.tableData"
            :columns="reportColumns"
            :paginator="true"
            :rows="10"
            class="p-datatable-sm"
            :global-filter-fields="reportColumns.map((col) => col.field)"
            :sortOrder="-1"
            :sortField="reportColumns[0]?.field"
            :filters="globalFilter"
          >
            <template #header>
              <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="globalFilter.global.value"
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </template>
            <Column
              v-for="col in reportColumns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              sortable
            />
          </DataTable>

          <Button
            @click="exportCSV"
            label="Download CSV"
            class="mt-4 p-button-secondary"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "CustomReportPage",
  components: {
    Calendar,
    MultiSelect,
    Checkbox,
    Button,
    ProgressSpinner,
    DataTable,
    Column,
    Card,
    InputText,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const dateRange = ref(null);
    const selectedPartners = ref([]);
    const selectedGroups = ref([]);
    const selectedSellers = ref([]);
    const selectedDimensions = ref([]);
    const selectedMetrics = ref([]);
    const isLoading = ref(false);
    const reportData = ref(null);
    const visibleDatasets = ref([]);
    const validationWarnings = ref([]);
    const memberList = ref({ partners: [], groups: [], sellers: [] });

    const globalFilter = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const dimensions = [
      { name: "Date", isTagRelated: false, field: "date" },
      { name: "Partner ID", isTagRelated: false, field: "partner_id" },
      { name: "Partner Name", isTagRelated: false, field: "partner_name" },
      { name: "Seller ID", isTagRelated: false, field: "seller_id" },
      { name: "Seller Name", isTagRelated: false, field: "seller_name" },
      { name: "Geo", isTagRelated: false, field: "geo" },
      { name: "Domain", isTagRelated: false, field: "domain" },
      { name: "Tag ID", isTagRelated: true, field: "tag_id" },
      { name: "Tag Name", isTagRelated: true, field: "tag_name" },
      { name: "Tag Partner", isTagRelated: true, field: "tag_partner" },
    ];

    const metrics = [
      { name: "Player Loads", isLoadRelated: true, field: "player_loads" },
      { name: "Ad Impressions", isLoadRelated: false, field: "ad_impressions" },
      { name: "Revenue", isLoadRelated: false, field: "revenue" },
      { name: "Cost", isLoadRelated: false, field: "cost" },
      { name: "Profit", isLoadRelated: false, field: "profit" },
      { name: "Profit Margin", isLoadRelated: false, field: "profit_margin" },
      { name: "RPM", isLoadRelated: false, field: "rpm" },
      { name: "CPM", isLoadRelated: false, field: "cpm" },
      { name: "RPL", isLoadRelated: true, field: "rpl" },
      { name: "CPL", isLoadRelated: true, field: "cpl" },
      { name: "Fill Rate", isLoadRelated: false, field: "fill_rate" },
      { name: "Tag Requests", isTagRelated: true, field: "tag_requests" },
    ];

    const reportColumns = computed(() => {
      return [
        ...selectedDimensions.value.map((d) => ({
          field: d.field,
          header: d.name,
        })),
        ...selectedMetrics.value.map((m) => ({
          field: m.field,
          header: m.name,
        })),
      ];
    });

    const isTagRelatedDimensionSelected = computed(() => {
      return selectedDimensions.value.some((d) => d.isTagRelated);
    });

    const availableMetrics = computed(() => {
      if (isTagRelatedDimensionSelected.value) {
        return metrics;
      } else {
        return metrics.filter((m) => !m.isTagRelated);
      }
    });

    const isValid = computed(() => {
      return (
        validationWarnings.value.length === 0 &&
        dateRange.value &&
        selectedDimensions.value.length > 0 &&
        selectedMetrics.value.length > 0
      );
    });

    const runReportButtonLabel = computed(() => {
      if (!dateRange.value) return "Select Date Range";
      if (selectedDimensions.value.length === 0) return "Select Dimensions";
      if (selectedMetrics.value.length === 0) return "Select Metrics";
      if (!isValid.value) return "Resolve Warnings";
      return "Run Report";
    });

    const validateSelections = () => {
      validationWarnings.value = [];

      const tagRelatedDimensions = selectedDimensions.value.filter(
        (d) => d.isTagRelated
      );
      const loadRelatedMetrics = selectedMetrics.value.filter(
        (m) => m.isLoadRelated
      );

      if (tagRelatedDimensions.length > 0 && loadRelatedMetrics.length > 0) {
        validationWarnings.value.push(
          "Tag-related dimensions cannot be chosen with load-related metrics."
        );
      }

      if (
        !isTagRelatedDimensionSelected.value &&
        selectedMetrics.value.some((m) => m.name === "Tag Requests")
      ) {
        validationWarnings.value.push(
          "Tag Requests metric requires a tag-related dimension."
        );
      }

      // Remove any metrics that are no longer valid
      selectedMetrics.value = selectedMetrics.value.filter((m) =>
        availableMetrics.value.some((am) => am.name === m.name)
      );
    };

    const fetchMemberList = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const { data } = await axios.get(API_BASE_URL + "/member-list/all", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          const { partners, groups, sellers } = data;
          memberList.value = { partners, groups, sellers };
        }
      } catch (error) {
        console.error("Error fetching member list:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch member list",
          life: 3000,
        });
      }
    };

    const runReport = async () => {
      if (!isValid.value) {
        toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Please resolve all warnings before running the report",
          life: 3000,
        });
        return;
      }

      isLoading.value = true;
      try {
        const response = await axios.post(
          `${API_BASE_URL}/admin/custom-report`,
          {
            dateRange: dateRange.value,
            partners:
              selectedPartners.value.length > 0 ? selectedPartners.value : [],
            groups: selectedGroups.value.length > 0 ? selectedGroups.value : [],
            sellers:
              selectedSellers.value.length > 0 ? selectedSellers.value : [],
            dimensions: selectedDimensions.value.map((d) => d.name),
            metrics: selectedMetrics.value.map((m) => m.name),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          }
        );

        const formattedData = formatData(response.data);
        reportData.value = formattedData;
        visibleDatasets.value = reportData.value.chartData.map(
          (_, index) => index
        );
        toast.add({
          severity: "success",
          summary: "Report Generated",
          detail: "Your report has been generated successfully",
          life: 3000,
        });
      } catch (error) {
        console.error("Error fetching report data:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to generate report",
          life: 3000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const formatData = (data) => {
      data.tableData = data.tableData.map((item) => {
        if (item.profit_margin)
          item.profit_margin = item.profit_margin.toFixed(2) * 100 + "%";
        if (item.fill_rate)
          item.fill_rate = item.fill_rate.toFixed(2) * 100 + "%";
        return item;
      });
      return data;
    };

    const exportCSV = () => {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(reportData.value.tableData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "csv",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "report");
      });
    };

    const saveAsExcelFile = (buffer, fileName) => {
      import("file-saver").then((module) => {
        if (module && module.default) {
          let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          let EXCEL_EXTENSION = ".csv";
          const data = new Blob([buffer], {
            type: EXCEL_TYPE,
          });
          module.default.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
          );
        }
      });
    };

    const updateQueryParams = () => {
      router.replace({
        query: {
          dateRange: dateRange.value ? JSON.stringify(dateRange.value) : null,
          partners: selectedPartners.value.join(","),
          groups: selectedGroups.value.join(","),
          sellers: selectedSellers.value.join(","),
          dimensions: selectedDimensions.value.map((d) => d.name).join(","),
          metrics: selectedMetrics.value.map((m) => m.name).join(","),
        },
      });
    };

    // New function to parse URL query parameters
    const parseQueryParams = () => {
      const {
        dateRange: dateRangeParam,
        partners: partnersParam,
        groups: groupsParam,
        sellers: sellersParam,
        dimensions: dimensionsParam,
        metrics: metricsParam,
      } = route.query;

      if (dateRangeParam) {
        const parsedDateRange = JSON.parse(dateRangeParam);
        dateRange.value = [
          new Date(parsedDateRange[0]),
          new Date(parsedDateRange[1]),
        ];
      }
      if (partnersParam) selectedPartners.value = partnersParam.split(",");
      if (groupsParam) selectedGroups.value = groupsParam.split(",");
      if (sellersParam) selectedSellers.value = sellersParam.split(",");
      if (dimensionsParam) {
        selectedDimensions.value = dimensionsParam.split(",").map(
          (d) =>
            dimensions.find((dim) => dim.name === d) || {
              name: d,
              isTagRelated: false,
            }
        );
      }
      if (metricsParam) {
        selectedMetrics.value = metricsParam.split(",").map(
          (m) =>
            metrics.find((met) => met.name === m) || {
              name: m,
              isLoadRelated: false,
            }
        );
      }
    };

    // Watch for changes in form fields and update URL
    watch(
      [
        dateRange,
        selectedPartners,
        selectedGroups,
        selectedSellers,
        selectedDimensions,
        selectedMetrics,
      ],
      () => {
        updateQueryParams();
      }
    );

    onMounted(() => {
      fetchMemberList();
      parseQueryParams();
    });

    return {
      dateRange,
      selectedPartners,
      selectedGroups,
      selectedSellers,
      selectedDimensions,
      selectedMetrics,
      isLoading,
      reportData,
      dimensions,
      metrics,
      memberList,
      availableMetrics,
      runReport,

      validateSelections,
      validationWarnings,
      isValid,
      runReportButtonLabel,
      reportColumns,
      globalFilter,
      exportCSV,
    };
  },
};
</script>

<style scoped>
.custom-report-page {
  margin: 0 auto;
  padding: 0px 5px;
}

.chart-container {
  height: 400px;
}

.validation-warnings {
  padding: 1rem;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 0.25rem;
  color: #856404;
}

.validation-warnings ul {
  margin: 0;
  padding-left: 1.25rem;
}

h1 {
  color: var(--text-color);
}

.p-card {
  padding: 0px 10px;
}
</style>
