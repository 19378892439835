<template>
  <div
    class="flex align-items-center h-full px-3 w-full sm:w-9 md:w-8 lg:w-7 xl:w-5 mx-auto"
  >
    <div class="p-4 w-full">
      <div class="text-center mb-5">
        <JamxLogo class="w-6rem h-6rem" logo-color="#d9ff3f" />
        <div
          class="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-3 text-900"
        >
          Welcome Back
        </div>
        <span class="text-900 text-xl sm:text-2xl font-medium line-height-3">{{
          isForgotPassword
            ? "Enter your email address to receive a code and reset your password."
            : "Login to access your data"
        }}</span>
      </div>

      <div>
        <div class="flex justify-content-end">
          <Button
            v-if="isForgotPassword"
            @click="isForgotPassword = false"
            label="Back to Login"
            text
            class="p-0 -mt-3 mb-2"
            size="small"
          />
        </div>
        <ForgotPasswordForm
          v-if="isForgotPassword"
          @go-login="isForgotPassword = false"
        />
        <form v-else @submit.prevent="login">
          <label for="email1" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            v-model="username"
            id="email1"
            placeholder="Username"
            required
            @input="errorMessage = ''"
            type="text"
            class="w-full mb-3 p-3 text-900"
            style="background: #333; border: #333; box-shadow: none"
          />

          <div class="flex justify-content-between">
            <label for="password1" class="block text-900 font-medium mb-2"
              >Password</label
            >
            <Button
              @click="isForgotPassword = true"
              label="Forgot Password?"
              text
              class="p-0 -mt-3"
              size="small"
            />
          </div>
          <InputText
            id="password1"
            type="password"
            v-model="password"
            placeholder="Password"
            required
            @input="errorMessage = ''"
            class="w-full mb-3 p-3 text-900"
            style="background: #333; border: #333; box-shadow: none"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <Button
            type="submit"
            label="Sign In"
            icon="pi pi-user"
            class="w-full mt-4"
            style="
              background-color: #d9ff3f;
              border-color: #d9ff3f;
              color: #333;
            "
          ></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import JamxLogo from "./icons/JamxLogo.vue";
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";

const username = ref("");
const password = ref("");
const errorMessage = ref("");
const isForgotPassword = ref(false);
const store = useStore();
const router = useRouter();

const userTypeStrArr = {
  admin: {
    commitStr: "setAdmin",
    tokenStr: "adminToken",
    pathStr: "/admin",
  },
  partner: { commitStr: "setPartner", tokenStr: "token", pathStr: "" },
  "group-admin": {
    commitStr: "setGroupAdmin",
    tokenStr: "groupAdminToken",
    pathStr: "/group-admin",
  },
  seller: {
    commitStr: "setSeller",
    tokenStr: "sellerToken",
    pathStr: "/seller",
  },
  podcaster: {
    commitStr: "setPodcaster",
    tokenStr: "podcasterToken",
    pathStr: "/podcaster",
  },
  podcastAdmin: {
    commitStr: "setPodcastAdmin",
    tokenStr: "podcastAdminToken",
    pathStr: "/podcast-admin",
  },
};

const login = async () => {
  localStorage.clear();
  try {
    const response = await axios.post(API_BASE_URL + "/login/aio", {
      username: username.value,
      password: password.value,
    });
    if (response.data.success) {
      const userData = response.data.user;
      store.commit("setLoggedIn", true);
      localStorage.setItem(
        userTypeStrArr[userData.role].tokenStr,
        response.data.token
      );
      store.commit(userTypeStrArr[userData.role].commitStr, userData);

      if (response.data.connectedPodcaster?.connectedPodcaster) {
        const connectedPodcasterData = response.data.connectedPodcaster;
        localStorage.setItem(
          "podcasterToken",
          connectedPodcasterData.connectedPodcasterToken
        );
        store.commit("setPodcaster", connectedPodcasterData.connectedPodcaster);
        store.commit("setBothRole", true);
      }

      // Redirect to the dashboard
      router.push(`${userTypeStrArr[userData.role].pathStr}/dashboard`);
    } else {
      console.log("Invalid username or password.");
      errorMessage.value = "Invalid username or password.";
    }
  } catch (error) {
    // Handle errors
    errorMessage.value = "Invalid username or password.";
  }
};
</script>

<style scoped>
/* ... existing styles ... */
.error-message {
  color: red;
  margin-bottom: 1rem;
}
</style>
