<template>
  <div>
    <Message
      v-if="!sellerData?.adstxt_verified"
      class="select-none cursor-pointer"
      :closable="false"
      :severity="sellerData?.adstxt_last_check ? 'error' : 'warn'"
      @click="
        sellerData?.adstxt_last_check
          ? (showAdstxtVerificationResult = true)
          : null
      "
    >
      {{
        sellerData?.adstxt_last_check
          ? "Ads.txt validation failed. Click for more details."
          : "Ads.txt validation has not been done yet"
      }}
    </Message>
    <h2 class="mt-0" style="color: var(--text-color)">Dashboard</h2>
    <div class="mb-4 flex flex-column sm:flex-row gap-2 sm:gap-3">
      <Calendar
        v-model="dateRange"
        :showIcon="true"
        :number-of-months="2"
        :selectionMode="'range'"
        @update:modelValue="setDateRange"
      >
      </Calendar>
      <Button @click="fetchOnDateRange">Fetch Data</Button>
    </div>

    <DashboardSection
      v-for="section in sections"
      :key="section.dataMode"
      v-bind="section"
      :data="data[section.dataMode]"
      :loading="loading[section.dataMode]"
      :rows="rows"
      :totalRecords="totalRecords[section.dataMode] * 1"
      @fetch-data="fetchData"
      @download-csv="downloadCsv(section.dataMode, section.dataMode + '.csv')"
    />
  </div>
  <AdstxtMissingLinesModal
    :visible="showAdstxtVerificationResult"
    :missing-lines="sellerData?.adstxt_missing_lines"
    :last-checked="sellerData?.adstxt_last_check"
    @close-modal="showAdstxtVerificationResult = false"
  />
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Message from "primevue/message";
import { useStore } from "vuex";
import { adjustDate } from "../helpers";
import DashboardSection from "./DashboardSection.vue";
import AdstxtMissingLinesModal from "./AdstxtValResult/AdstxtMissingLinesModal.vue";

export default {
  components: {
    Calendar,
    Button,
    Message,
    DashboardSection,
    AdstxtMissingLinesModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const groupAdminMode = route.name == "GroupAdminSellerDashboard";
    const sellerData = store.getters.seller;
    const showAdstxtVerificationResult = ref(false);

    const sellerId = router.currentRoute.value.params.sellerId;

    const createColumns = (includeGeo = false) => {
      const columns = [
        { header: "Date", field: "date_trunc" },
        { header: "Player Loads", field: "loads" },
        { header: "Ad Impressions", field: "impressions" },
        { header: "Revenue", field: "revenue" },
      ];

      if (includeGeo) {
        columns.splice(1, 0, { header: "Geo", field: "geo" });
      }

      if (sellerId && !groupAdminMode) {
        columns.push(
          { header: "Cost", field: "cost" },
          { header: "Profit", field: "profit" }
        );
      }

      columns.push({ header: "ECPM", field: "ecpm" });

      return columns;
    };

    const sections = [
      {
        title: "Partner Daily",
        dataMode: "partner_daily",
        columns: createColumns(),
        showChart: true,
      },
      {
        title: "Partner Monthly",
        dataMode: "partner_monthly",
        columns: createColumns(),
        showChart: false,
      },
      {
        title: "Per Geo Location",
        dataMode: "partner_per_geo_daily",
        columns: createColumns(true),
        showChart: true,
        chartLegend: "bottom",
      },
    ];

    const rows = ref(10);
    const totalRecords = reactive({
      partner_daily: 0,
      partner_monthly: 0,
      partner_per_geo_daily: 0,
    });
    const data = reactive({
      partner_daily: [],
      partner_monthly: [],
      partner_per_geo_daily: [],
    });

    const csvData = reactive({
      partner_daily: [],
      partner_monthly: [],
      partner_per_geo_daily: [],
    });

    const loading = reactive({
      partner_daily: false,
      partner_monthly: false,
      partner_per_geo_daily: false,
    });

    const dateRange = ref([
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ]);

    const setDateRange = (value) => {
      dateRange.value = value;
    };

    const fetchData = async ({
      dataMode,
      page = 0,
      rows = 10,
      sortField,
      sortOrder,
      fetchCsvData = false,
    }) => {
      try {
        loading[dataMode] = true;
        let token = "";

        if (sellerId) {
          if (groupAdminMode) token = localStorage.getItem("groupAdminToken");
          else token = localStorage.getItem("token");
        } else {
          token = localStorage.getItem("sellerToken");
        }

        const startDate = adjustDate(dateRange.value?.[0]);
        const endDate = adjustDate(dateRange.value?.[1]);

        const params = {
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
          dataMode: fetchCsvData ? `${dataMode}_all` : dataMode,
          page,
          rows,
          sortField,
          sortOrder,
          sellerId,
        };

        const { data: responseData } = await axios.get(
          `${API_BASE_URL}/seller/dashboard`,
          {
            params,
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (responseData.success) {
          if (fetchCsvData) {
            csvData[dataMode] = responseData.data[`${dataMode}`] || [];
          } else {
            data[dataMode] = responseData.data[dataMode] || [];
            totalRecords[dataMode] =
              responseData.data[dataMode]?.[0]?.count || 0;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (fetchCsvData) {
          csvData[dataMode] = [];
        } else {
          data[dataMode] = [];
          totalRecords[dataMode] = 0;
        }
      } finally {
        loading[dataMode] = false;
      }
    };

    const fetchCsvData = async (dataMode) => {
      await fetchData({ dataMode, fetchCsvData: true });
    };

    const fetchOnDateRange = () => {
      sections.forEach((section) => {
        fetchData({ dataMode: section.dataMode });
      });
    };

    onMounted(() => {
      fetchOnDateRange();
    });

    const downloadCsv = async (dataMode, filename) => {
      await fetchCsvData(dataMode);
      const fetchedData = csvData[dataMode];
      console.log(fetchedData);
      const csvContent =
        "data:text/csv;charset=utf-8," +
        fetchedData.map((row) => Object.values(row).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      sellerData,
      showAdstxtVerificationResult,
      sections,
      rows,
      totalRecords,
      data,
      loading,
      dateRange,
      setDateRange,
      fetchData,
      fetchOnDateRange,
      downloadCsv,
    };
  },
};
</script>
