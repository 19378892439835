<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{
        $router.currentRoute.value.name == "UpdatePodcastAdminCampaign"
          ? "Update"
          : "Add"
      }}
      Campaign
    </h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Campaign Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="show_gross_profit">Show</label>
              <Dropdown
                v-model="form.show_id"
                :options="shows"
                optionValue="show_id"
                optionLabel="show_title"
                checkmark
                filter
                placeholder="Podcast Show"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-3">
              <label for="show_gross_profit">Start Date</label>
              <Calendar
                v-model="startDate"
                selectionMode="single"
                :manualInput="false"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-3">
              <label for="show_gross_profit">End Date</label>
              <Calendar
                v-model="endDate"
                selectionMode="single"
                :manualInput="false"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_wl">Geo Whitelist</label>
              <MultiSelect
                inputId="geo_wl"
                v-model="form.geos_wl"
                :options="countries"
                option-value="geo"
                option-label="name"
                placeholder="Select Countries"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_bl">Geo Blacklist</label>
              <MultiSelect
                inputId="geo_bl"
                v-model="form.geo_bl"
                :options="countries"
                option-value="geo"
                option-label="name"
                placeholder="Select Countries"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="sellers_wl">Seller Whitelist</label>
              <MultiSelect
                inputId="sellers_wl"
                v-model="form.sellers_wl"
                :options="sellers"
                option-value="value"
                :optionLabel="(option) => `[${option.id}] ${option.name}`"
                placeholder="Select Seller"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="sellers_bl">Seller Blacklist</label>
              <MultiSelect
                inputId="sellers_bl"
                v-model="form.sellers_bl"
                :options="sellers"
                option-value="value"
                :optionLabel="(option) => `[${option.id}] ${option.name}`"
                placeholder="Select Seller"
                filter
                class="w-full"
              />
            </div>
            <div class="col-12 md:col-3 flex flex-column gap-1">
              <label for="campaign_type">Campaign Type</label>
              <Dropdown
                id="campaign_type"
                v-model="campaignType"
                :options="campaignTypeOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Choose Campaign Type"
              />
              <div v-if="campaignType === 'publisher'">
                <label for="publisher_id">Publisher ID</label>
                <Dropdown
                  id="publisher_id"
                  v-model="form.publisher_id"
                  :options="sellers"
                  optionValue="value"
                  :optionLabel="(option) => `[${option.id}] ${option.name}`"
                  placeholder="Select Publisher"
                  :class="{
                    'p-invalid':
                      submitted &&
                      campaignType === 'publisher' &&
                      !form.publisher_id,
                  }"
                  filter
                />
                <small
                  v-if="
                    submitted &&
                    campaignType === 'publisher' &&
                    !form.publisher_id
                  "
                  class="p-error"
                >
                  Publisher ID is required
                </small>
              </div>
            </div>
            <div class="col-12 md:col-3 flex flex-column gap-1">
              <label for="status">Status</label>
              <Dropdown
                id="status"
                v-model="status"
                :options="statusOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Choose Status"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="grid">
                <div class="col-3">
                  <label for="growth_percentage">Growth (%)</label>
                  <InputNumber
                    id="growth_percentage"
                    v-model="growthPercentage"
                    :min="0"
                    :max="100"
                    suffix="%"
                    class="w-full"
                    :inputStyle="{ width: '100%' }"
                  />
                </div>
                <div class="col-6">
                  <label for="promotions_per_interval"
                    >Promotions Per Interval</label
                  >
                  <div class="p-inputgroup">
                    <InputText
                      id="promotions_per_interval"
                      v-model="form.promotions_per_interval"
                      :class="{
                        'p-invalid': submitted && !form.promotions_per_interval,
                      }"
                      :disabled="status == 'Mellow mode'"
                    />
                    <Button
                      type="button"
                      icon="pi pi-calculator"
                      @click="calculatePromotions"
                      :disabled="status == 'Mellow mode'"
                    />
                  </div>
                </div>
                <div class="col-3">
                  <label for="ctr">CTR</label>
                  <InputText
                    id="ctr"
                    v-model="form.ctr"
                    :class="{ 'p-invalid': submitted && !form.ctr }"
                    :disabled="status == 'Mellow mode'"
                  />
                  <small v-if="submitted && !form.ctr" class="p-error"
                    >CTR is required</small
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import moment from "moment";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import country from "country-list-js";
import InputNumber from "primevue/inputnumber";

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { ref, computed, onMounted, watch } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";

const router = useRouter();
const store = useStore();
const toast = useToast();
const countries = ref([]);
const startDate = ref(new Date(moment()));
const endDate = ref(new Date(moment().add(3, "months")));
const submitted = ref(false);
const form = ref({
  campaign_id: "",
  show_id: "",
  geos_wl: ["us", "ca", "au", "gb"],
  geo_bl: [],
  sellers_wl: [
    "5001_2160",
    "5001_2161",
    "5000_1239",
    "5000_1227",
    "5000_1416",
    "5000_1546",
    "5001_2794",
    "5000_2667",
    "5001_2625",
    "5000_1382",
    "5000_1437",
    "5014_2857",
    "5000_1238",
    "5000_1276",
    "5000_1427",
    "5000_1403",
    "5001_2427",
    "5001_2234",
    "5001_2636",
    "5000_1127",
    "5013_2860",
    "5000_1225",
    "5000_1417",
    "5000_1275",
    "5000_1433",
    "5001_2626",
    "5000_1379",
    "5000_2814",
    "5000_1242",
    "5000_1445",
    "5000_1272",
    "5001_1036",
    "5000_1520",
    "5000_1426",
    "5000_1393",
    "5013_2856",
    "5000_1286",
    "5000_1421",
    "5013_2836",
    "5001_1335",
    "5013_2837",
    "5013_2850",
    "5000_1385",
    "5001_2786",
    "5000_1204",
    "5001_2162",
    "5001_2678",
    "5000_2644",
    "5000_1407",
    "5000_1542",
    "5000_1467",
    "5000_1383",
    "5000_1191",
    "5000_1408",
    "5000_1538",
    "5000_1199",
    "5000_2668",
    "5000_1386",
    "5001_2635",
    "5000_1423",
    "5000_1432",
    "5000_2643",
    "5000_1389",
    "5000_1378",
    "5000_1380",
    "5000_1270",
    "5001_2415",
    "5001_2795",
    "5000_1404",
    "5000_1399",
    "5000_1450",
    "5000_1397",
  ],
  sellers_bl: [],
  promotions_per_interval: 0,
  ctr: 0,
  publisher_id: null,
});
const status = ref("pending");
const growthPercentage = ref(5);

const statusOptions = [
  { label: "active", value: "active" },
  { label: "pending", value: "pending" },
  { label: "in-active", value: "in-active" },
  { label: "Mellow mode", value: "Mellow mode" },
];
const campaignType = ref("promotion");
const campaignTypeOptions = [
  { label: "promotion", value: "promotion" },
  { label: "publisher", value: "publisher" },
];
const campaignId = router.currentRoute.value.params.campaignId;
const token = localStorage.getItem("podcastAdminToken");

const shows = computed(() => {
  let tempShowList = store.getters.shows.filter(
    (item) => item.show_title && item.show_title != ""
  );
  if (!tempShowList) {
    tempShowList = [];
  }
  return tempShowList;
});

const fetchShows = async () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

const sellers = computed(() => store.getters.sellers || []);

const fetchSellers = () => {
  if (!store.getters.sellers || store.getters.sellers.length == 0) {
    store.dispatch("loadSellers", { token });
  }
};

const getCampaignData = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      API_BASE_URL + "/campaigns/" + campaignId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      const {
        campaign_id,
        show_id,
        start_date,
        end_date,
        geos_wl,
        geo_bl,
        sellers_wl,
        sellers_bl,
        promotions_per_interval,
        ctr,
        publisher_id,
      } = response.data.campaign;

      form.value = {
        ...form.value,
        campaign_id,
        show_id,
        geos_wl: geos_wl
          ?.toLowerCase()
          .split(",")
          .filter((item) => item != ""),
        geo_bl: geo_bl
          ?.toLowerCase()
          .split(",")
          .filter((item) => item != ""),
        sellers_wl: sellers_wl?.split(",").filter((item) => item != ""),
        sellers_bl: sellers_bl?.split(",").filter((item) => item != ""),
        promotions_per_interval,
        ctr,
        publisher_id: publisher_id || null,
      };
      status.value = response.data.campaign.status;
      campaignType.value = response.data.campaign.campaign_type;
      startDate.value = new Date(start_date);
      endDate.value = new Date(end_date);
    } else
      toast.add({
        severity: "error",
        summary: "Campaign data not found",
        life: 4000,
      });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Something went wrong...",
      life: 4000,
    });
  }
  store.commit("setLoading", false);
};

const submitForm = async () => {
  submitted.value = true;
  if (startDate.value >= endDate.value) {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail: `End date must be later than start date!`,
      life: 4000,
    });
    return;
  }
  if (
    form.value.show_id &&
    form.value.ctr &&
    startDate.value &&
    endDate.value &&
    (campaignType.value !== "publisher" || form.value.publisher_id)
  ) {
    try {
      store.commit("setLoading", true);

      const start_date = startDate.value.toISOString();
      const end_date = endDate.value.toISOString();

      const response = await axios.post(
        API_BASE_URL + "/campaigns/add",
        {
          start_date,
          end_date,
          ...form.value,
          status: status.value,
          campaign_type: campaignType.value,
          geos_wl: form.value.geos_wl?.join() || "",
          geo_bl: form.value.geo_bl?.join() || "",
          sellers_wl: form.value.sellers_wl?.join() || "",
          sellers_bl: form.value.sellers_bl?.join() || "",
          publisher_id: form.value.publisher_id || "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data?.success) {
        // Clear form after successful submission
        form.value = {
          campaign_id: "",
          show_id: "",
          geos_wl: ["us", "ca", "au", "gb", "uk"],
          geo_bl: [],
          sellers_wl: [
            "5001_2160",
            "5001_2161",
            "5000_1239",
            "5000_1227",
            "5000_1416",
            "5000_1546",
            "5001_2794",
            "5000_2667",
            "5001_2625",
            "5000_1382",
            "5000_1437",
            "5014_2857",
            "5000_1238",
            "5000_1276",
            "5000_1427",
            "5000_1403",
            "5001_2427",
            "5001_2234",
            "5001_2636",
            "5000_1127",
            "5013_2860",
            "5000_1225",
            "5000_1417",
            "5000_1275",
            "5000_1433",
            "5001_2626",
            "5000_1379",
            "5000_2814",
            "5000_1242",
            "5000_1445",
            "5000_1272",
            "5001_1036",
            "5000_1520",
            "5000_1426",
            "5000_1393",
            "5013_2856",
            "5000_1286",
            "5000_1421",
            "5013_2836",
            "5001_1335",
            "5013_2837",
            "5013_2850",
            "5000_1385",
            "5001_2786",
            "5000_1204",
            "5001_2162",
            "5001_2678",
            "5000_2644",
            "5000_1407",
            "5000_1542",
            "5000_1467",
            "5000_1383",
            "5000_1191",
            "5000_1408",
            "5000_1538",
            "5000_1199",
            "5000_2668",
            "5000_1386",
            "5001_2635",
            "5000_1423",
            "5000_1432",
            "5000_2643",
            "5000_1389",
            "5000_1378",
            "5000_1380",
            "5000_1270",
            "5001_2415",
            "5001_2795",
            "5000_1404",
            "5000_1399",
            "5000_1450",
            "5000_1397",
          ],
          promotions_per_interval: 0,
          ctr: 0,
        };
        status.value = "pending";
        campaignType.value = "promotion";

        submitted.value = false;
        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Campaign ${campaignId ? "updated" : "added"} successfully!`,
          life: 4000,
        });
        store.dispatch("loadCampaigns", { token });
        router.replace({ name: "PodcastAdminCampaignsList" });
      } else {
        toast.add({
          severity: "error",
          detail: `Something went wrong...`,
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        detail: `Something went wrong...`,
        life: 4000,
      });
      console.log(error);
    } finally {
      store.commit("setLoading", false);
    }
  } else {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail: `Fill in the blinks, please!`,
      life: 4000,
    });
  }
};

watch([status], () => {
  if (status.value == "Mellow mode") {
    form.value.promotions_per_interval = 0;
    form.value.ctr = 0.18;
    growthPercentage.value = 5; // Reset growth percentage to default
  }
});

onMounted(async () => {
  countries.value = country.names().map((name) => {
    const data = country.findByName(name);
    return {
      name,
      geo: data.code.iso2.toLowerCase(),
    };
  });
  fetchShows();
  fetchSellers();
  if (campaignId) {
    form.value.campaign_id = campaignId;
    getCampaignData();
  }
});

const calculatePromotions = () => {
  if (!form.value.show_id) {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail: "Please select a show first.",
      life: 4000,
    });
    return;
  }

  const selectedShow = shows.value.find(
    (show) => show.show_id === form.value.show_id
  );
  if (
    !selectedShow ||
    !selectedShow.avg_per_month ||
    isNaN(selectedShow.avg_per_month)
  ) {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail:
        "Selected show does not have a valid average listeners per month value.",
      life: 4000,
    });
    return;
  }

  const avgPerMonth = parseFloat(selectedShow.avg_per_month);
  const listenersPerHour = avgPerMonth / 30 / 24;
  const promotionsPerInterval =
    listenersPerHour * (growthPercentage.value / 100);

  form.value.promotions_per_interval = promotionsPerInterval.toFixed(2);
};
</script>

<style scoped>
.p-inputnumber,
.p-inputtext,
.p-inputgroup {
  width: 100%;
}

.p-inputgroup {
  display: flex;
}

.p-inputgroup .p-inputtext {
  flex: 1;
}

.p-inputgroup .p-button {
  flex: 0 0 auto;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

/* Ensure inputs don't overflow their containers */
input {
  max-width: 100%;
}
</style>
