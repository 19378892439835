<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Filtered Tags List</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Dropdown
            input-id="sub-sellers"
            filter
            :options="memberList.sellers"
            v-model="selectedSubSeller"
            :optionLabel="(option) => `[${option.id}] ${option.name}`"
            :optionValue="(option) => `${option.partner_id}_${option.id}`"
            placeholder="Select Sub-Seller"
            class="w-full sm:w-20rem"
          />
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            placeholder="Geo"
            v-model="geo"
          />
          <Button
            label="Fetch Tags"
            @click="fetchFilteredTags"
            :loading="loading"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="filteredTags"
          class="p-datatable-sm"
          :loading="loading"
        >
          <Column field="id" header="Tag ID" :sortable="true"></Column>
          <Column field="name" header="Tag Name" :sortable="true"></Column>
          <Column field="p" header="Tag Partner" :sortable="true"></Column>
        </DataTable>
        <div
          v-if="filteredTags.length === 0 && !loading"
          class="text-center p-4"
        >
          No tags found for the selected criteria.
        </div>
      </template>
    </Card>

    <h3 class="mt-4">Potential Demand to Connect</h3>
    <DataTable :value="potentialDemands" class="p-datatable-sm">
      <Column
        field="partnerName"
        header="Partner Name"
        :sortable="true"
      ></Column>
      <Column field="adsTxtStatus" header="Ads.txt Status" :sortable="true">
        <template #body="slotProps">
          <span
            :class="
              slotProps.data.adsTxtStatus === 'GREEN'
                ? 'text-green-500'
                : 'text-red-500'
            "
          >
            {{ slotProps.data.adsTxtStatus }}
          </span>
        </template>
      </Column>
    </DataTable>

    <h3 class="mt-4">Clone Whitelist from Seller</h3>
    <div class="flex gap-3">
      <Dropdown
        input-id="clone-from-seller"
        filter
        :options="memberList.sellers"
        v-model="cloneFromSeller"
        :optionLabel="(option) => `[${option.id}] ${option.name}`"
        :optionValue="(option) => `${option.partner_id}_${option.id}`"
        placeholder="Select Seller to Clone From"
        class="w-full sm:w-20rem"
      />
      <Button
        label="Clone Whitelist"
        @click="showConfirmDialog"
        :disabled="cloneLoading"
      />
    </div>

    <Dialog v-model:visible="confirmDialogVisible" :modal="true">
      <template #header>
        <h3>Confirm Cloning</h3>
      </template>
      <p>Are you sure you want to clone the whitelist?</p>
      <template #footer>
        <Button
          label="No"
          @click="confirmDialogVisible = false"
          class="p-button-text"
        />
        <Button
          label="Yes"
          @click="executeCloneWhitelist"
          :loading="cloneLoading"
          autofocus
        />
      </template>
    </Dialog>
    <Toast />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import { API_BASE_URL } from "../consts";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";

const memberList = ref({ sellers: [] });
const selectedSubSeller = ref(null);
const geo = ref("US");
const filteredTags = ref([]);
const potentialDemands = ref([]);
const loading = ref(false);
const cloneFromSeller = ref(null);
const cloneLoading = ref(false);
const confirmDialogVisible = ref(false);
const confirm = useConfirm();
const toast = useToast();

const fetchMemberList = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    const { data } = await axios.get(`${API_BASE_URL}/member-list/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (data.success) {
      memberList.value.sellers = data.sellers;
    }
  } catch (error) {
    console.error("Error fetching member list:", error);
  }
};

const fetchFilteredTags = async () => {
  loading.value = true;
  try {
    const token = localStorage.getItem("adminToken");
    let sellerId, subSellerId;

    if (selectedSubSeller.value) {
      [sellerId, subSellerId] = selectedSubSeller.value.split("_");
    }

    const response = await axios.get(`${API_BASE_URL}/admin/tags-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        sellerId,
        subSellerId,
        geo: geo.value,
      },
    });

    if (response.data && response.data.tags) {
      filteredTags.value = response.data.tags;
      potentialDemands.value = response.data.potentialDemands;
    } else {
      filteredTags.value = [];
      potentialDemands.value = [];
    }
  } catch (error) {
    console.error("Error fetching filtered tags:", error);
    filteredTags.value = [];
    potentialDemands.value = [];
  } finally {
    loading.value = false;
  }
};
const showConfirmDialog = () => {
  if (!cloneLoading.value) {
    confirmDialogVisible.value = true;
  }
};

const executeCloneWhitelist = async () => {
  if (cloneLoading.value) return;
  cloneLoading.value = true;
  confirmDialogVisible.value = false;

  try {
    const token = localStorage.getItem("adminToken");
    await axios.post(
      `${API_BASE_URL}/admin/clone-whitelist`,
      {
        fromSellerId: cloneFromSeller.value,
        toSellerId: selectedSubSeller.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.add({
      severity: "success",
      summary: "Whitelist Cloned",
      detail: "Whitelist has been cloned successfully",
      life: 3000,
    });
  } catch (error) {
    console.error("Error cloning whitelist:", error);
    toast.add({
      severity: "error",
      summary: "Cloning Failed",
      detail: "An error occurred while cloning the whitelist",
      life: 3000,
    });
  } finally {
    cloneLoading.value = false;
  }
};

onMounted(() => {
  fetchMemberList();
});
</script>

<style scoped>
h3 {
  color: var(--text-color);
}
</style>
